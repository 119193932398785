<template>
  <v-container fluid class="fill-height text-center">
    <v-row>
      <v-col>
        <v-img :src="config.appUrl + '/logo.png'"></v-img>
        <span class="headline">Page not found, redirecting...</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from '@/config';
export default {
  data() {
    return {
      config: config
    }
  },
  mounted() {
    window.location = "/"
  }
}
</script>
